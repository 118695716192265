var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab_content_area", attrs: { id: "tab3" } }, [
    _vm._m(0),
    _c("div", { staticClass: "container_tbl_wrap" }, [
      _c("div", { staticClass: "tbl_tit" }, [
        _vm._v(_vm._s(_vm.$t("msg.NEWB010P050.006"))),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "img_wrap", staticStyle: { height: "300px" } },
      [
        _c("img", {
          attrs: { src: require("../../../assets/images/trans/con_ot.png") },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tbl_wrap" }, [
      _c("table", { staticClass: "tbl_col" }, [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "auto" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
        ]),
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Type of "),
              _c("br"),
              _vm._v("Container"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Internal"),
              _c("br"),
              _vm._v("Dimension"),
              _c("br"),
              _vm._v("(mm)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Door"),
              _c("br"),
              _vm._v("Opening"),
              _c("br"),
              _vm._v("(mm)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Load"),
              _c("br"),
              _vm._v("Capacity"),
              _c("br"),
              _vm._v("(m"),
              _c("sup", [_vm._v("3")]),
              _vm._v(")"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Container"),
              _c("br"),
              _vm._v("Tare Weight"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Max "),
              _c("br"),
              _vm._v("Pay Load"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Max "),
              _c("br"),
              _vm._v("Gross Weight"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("20OT")]),
            _c("td", [
              _vm._v("L: 5,889"),
              _c("br"),
              _vm._v("W: 2,345"),
              _c("br"),
              _vm._v("H: 2,312"),
            ]),
            _c("td", [_vm._v("W: 2,335"), _c("br"), _vm._v("H: 2,277")]),
            _c("td", [_vm._v("31.8")]),
            _c("td", [_vm._v("2,020")]),
            _c("td", [_vm._v("28,000")]),
            _c("td", [_vm._v("30,250")]),
          ]),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("40OT")]),
            _c("td", [
              _vm._v("L: 12,044"),
              _c("br"),
              _vm._v("W: 2,350"),
              _c("br"),
              _vm._v("H: 2,348"),
            ]),
            _c("td", [_vm._v("W: 2,335"), _c("br"), _vm._v("H: 2,280")]),
            _c("td", [_vm._v("66.3")]),
            _c("td", [_vm._v("3,980")]),
            _c("td", [_vm._v("26,500")]),
            _c("td", [_vm._v("30,480")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }