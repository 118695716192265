<template>
  <div id="tab3" class="tab_content_area">
    <div class="img_wrap" style="height:300px;">
      <img src="../../../assets/images/trans/con_ot.png">
    </div>
    <div class="container_tbl_wrap">
      <div class="tbl_tit">{{ $t('msg.NEWB010P050.006') }}</div><!-- OPEN TOP CONTAINER -->
      <div class="tbl_wrap">
        <table class="tbl_col">
          <colgroup>
            <col style="width:14%">
            <col style="width:auto">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Type of <br>Container</th>
              <th scope="col">Internal<br>Dimension<br>(mm)</th>
              <th scope="col">Door<br>Opening<br>(mm)</th>
              <th scope="col">Load<br>Capacity<br>(m<sup>3</sup>)</th>
              <th scope="col">Container<br>Tare Weight<br>(Kg)</th>
              <th scope="col">Max <br>Pay Load<br>(Kg)</th>
              <th scope="col">Max <br>Gross Weight<br>(Kg)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">20OT</th>
              <td>L: 5,889<br>W: 2,345<br>H: 2,312</td>
              <td>W: 2,335<br>H: 2,277</td>
              <td>31.8</td>
              <td>2,020</td>
              <td>28,000</td>
              <td>30,250</td>
            </tr>
            <tr>
              <th scope="row">40OT</th>
              <td>L: 12,044<br>W: 2,350<br>H: 2,348</td>
              <td>W: 2,335<br>H: 2,280</td>
              <td>66.3</td>
              <td>3,980</td>
              <td>26,500</td>
              <td>30,480</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div><!-- content_box // -->
</template>

<script>
export default {
  name: 'ContainerOT'
}
</script>

<style scoped>

</style>
